<template>
  <div class="relatorio">
    <v-progress-circular class="ralatorio-load" color="primary" :width="4" :size="70" indeterminate />
    <v-container fluid>
      <iframe class="iframe" frameborder="0" height="90%" width="100%" :src="url"></iframe>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

// mixins para com funcao padroes na geração de relatorios
// import mixinRelatorio from '@/views/relatorios/mixins/relatorio'
// eslint-disable-next-line
import { map, size, last, each, uniqBy } from 'lodash'
export default {
  name: 'AnaliseClientes',
  data: () => ({
    request: '',
  }),
  beforeDestroy() {
    clearInterval(this.request)
    clearInterval(this.selector)
  },
  computed: {
    url () {
      let url = `https://analytics.zoho.com/open-view/2584235000002251296`
      return url
    },
  },
  methods: {
    ...mapActions('relatorioDreRede', ['getHtml', 'requestRelatorio'])
  }
}
</script>

<style lang="scss" scoped >
$cor: #0091ff;
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    .iframe {
      margin-top: 20px;
      min-height: calc(100vh - 112px + 24px);
      border: none;
      margin: -24px;
      width: calc(100% + 50px);
    }
  }
  .v-progress-circular{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1 !important;
  }
</style>